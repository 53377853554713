 @media (min-width: 576px) {
  .ms-sm-2{
    margin-left: 8px;
  }
  .d-sm-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}
@media (min-width: 992px) {
  .layout-dashboard .ant-layout-header {
    margin: 0px;
    padding: 5px 40px 30px;
}
  .layout-dashboard .ant-layout-footer {
    margin: 0;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 20px 0 0 20px;
    padding: 13px 20px;
    /* height: 100vh; */
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 0px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
  .ant-modal-body .ant-form-item .ant-row.ant-form-item-row {
    margin: 0 !important;
  }
  .footer-menu ul {
    justify-content: center;
  }
  footer.ant-layout-footer {
    padding: 0px 15px;
    text-align: center;
  }
  .project-ant {
    display: block;
  }

  .ant-filtertabs {
    text-align: right;
  }
  .rightside-top span.ant-input-affix-wrapper {
    width: 200px;
  }

  .rightside-top {
    justify-content: flex-end;
  }

  header.ant-layout-header.subheader {
    padding: 0px 24px 0px 0px;
  }

  main.ant-layout-content.content-ant {
    padding: 0 28px;
  }

  .ant-row {
    margin-left: -12px;
    margin-right: -12px;
  }
  span.ismobile {
    display: inline-block;
  }
  .bar-chart {
    width: 100% !important;
    max-width: 100%;
  }
  .full-width {
    max-width: 100% !important;
  }
  .bar-chart {
    width: 100% !important;
  }

  .icon-move-right {
    margin-bottom: 15px;
  }
  .pageheader {
    display: block;
  }
  .ant-cret img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
  .ant-modal-body form#create .ant-row {
      margin: 0 !important;
  }
} 
@media screen and (min-device-width: 1200px) and (max-device-width: 1440px) {
  .dash-box-top h4 {
    font-size: 24px;
    line-height: 35px;
}
.dash-box-top p { 
  font-size: 12px; 
  line-height: 16px; 
}
.sales-text-outer h4 { 
  font-size: 14px; 
  line-height: 18px; 
}
.sales-text-outer h6 { 
  font-size: 12px; 
  line-height: 14px; 
}
 
.sales-img-icon { 
  margin: 5px 5px;
  width: 40px;
  height: 40px;
  flex: 0 0 40px; 
}
}
@media (max-width:1599.98px) {
  .cls-total{
    margin: 10px 0px;
  }
    :root {
      --contactWidth: 400px;
  }
}
@media (max-width:1480.98px) {
  .customerMain .ant-card-extra, .specialistMain .ant-card-extra{
    flex-direction: column;
    align-items: unset;
    
  }
  .customerMain .ant-card-extra .button_group,  .specialistMain .ant-card-extra .button_group{
    justify-content: end;
    
  }
}
@media (max-width:1391.98px) {
  .wlt-section { 
    flex-wrap: wrap; 
}
  .wlt-text h6 {
    font-size: 14px; 
}
  .sign-up-bg section.ant-layout.signup-page{
    max-width: 100%;
  }
  .signin_img img {
    max-width: 100%;
}
  .ant-card-head-wrapper .ant-card-extra {
      gap: 5px !important;
  }
  /* .ant-card-head-wrapper .ant-card-extra .ant-btn {
      padding: 0 10px !important;
      font-size: 12px;
  } */
  .btnStyle {
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.04em !important;
    padding: 12px 18px !important;
}
.ant-card-head-title h4 {
  font-size: 22px;
}
.SectionMain > div {
  font-size: 24px;
}
.signin_img img {
  max-width: 100%;
}
}

@media (max-width:1199.98px){
  :root {
    --contactWidth: 300px;
}
  /* .ant-card-head-wrapper {
      flex-direction: column !important;
  } */
  .tablespace .ant-card-head-title {
      /* width: 100%; */
      font-size: 16px;
      padding-bottom: 0 !important;
      line-height: 1.5;
  }
  .ant-card-head-wrapper .ant-card-extra{
    padding-top: 0 !important;
  }
  
  .vehicleMake .ant-card-head-wrapper .ant-card-extra, .vehicleModal .ant-card-head-wrapper .ant-card-extra,
  .vehicleType .ant-card-head-wrapper .ant-card-extra, .categoryService .ant-card-head-wrapper .ant-card-extra, .brandManager .ant-card-head-wrapper .ant-card-extra,
  .productAttribute .ant-card-head-wrapper .ant-card-extra, .productCaralogue .ant-card-head-wrapper .ant-card-extra, .quoteManagement .ant-card-head-wrapper .ant-card-extra,
  .blog .ant-card-head-wrapper .ant-card-extra, .bannerMain .ant-card-head-wrapper .ant-card-extra {
    flex-direction: row;
  }
  .ant-card-head-wrapper .ant-card-extra {
    flex-direction: column;
    gap: 12px;
    align-items: end;
  }
  .ant-card-head {
    padding: 12px 18px 12px !important;
}
.ant-card-head-title h4 {
  font-size: 20px;
}
.title-left p {
  font-size: 14px;
  line-height: 1.4;
}
.btnStyle {
  font-size: 13px !important;
  line-height: 15px !important;
  letter-spacing: 0.04em !important;
  padding: 12.5px 13px !important;
}
.ant-card.ant-card-bordered.dashboard-cards {
  margin-bottom: 15px;
}

.ant-modal.tab_modal .ant-form label span {
  font-size: 12px;
}
.signin-box {
  padding: 0 35px;
}
.signin-box h1 {
  font-size: 20px !important; 
  line-height: 30px; 
}


}
@media (max-width: 991.98px) {
  .online-swich {
    position: absolute;
    top: -40px;
    right: 78px;
}
  .layout-dashboard .ant-layout-header {
    padding: 10px 10px !important;
}
  .layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler svg {
    height: 22px;
    width: 22px;
}
  span.anticon.anticon-delete.delete-circal {
    width: 42px;
    height: 42px;
  }
  .ant-modal.tab_modal .ant-modal-content{
    padding: 15px;
  }
  .ant-form-item-control-input-content input {
      padding: 12px 20px;
      min-height: 42px;
  }
  .ant-tabs.ant-tabs-top.main_tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn{
    padding: 12px 25px ;
  }
  /* .notificationDropdownMain .notification-header {
      right: 100px;
  } */
  .tabLogo {
    padding-left: 40px;
    width: 100%;
    max-width: 170px;
}
.layout-dashboard .header-control {
  gap: 10px !important;
  margin-top: 10px !important;
}
.layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler {
  position: absolute;
  left: 0;
  top: -45px;
}
.layout-dashboard .header-control .country-wrap {
  flex: 0 0 50%;
  max-width: 50%;
}
.layout-dashboard .header-control .language-wrap {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 10px;
}
.notification-header {
  position: absolute;
  top: -43px;
  right: 46px;
}
.profileDropdownMain {
  margin-left: 0;
  position: absolute;
  top: -50px;
  right: 0px;
}
.layout-dashboard .header-control .country-wrap .ant-select.ant-select-single.ant-select-show-arrow {
  width: 100%;
}
.layout-dashboard .header-control  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  min-width: 90px !important;
}
.layout-dashboard .header-control .ant-select-single .ant-select-selector .ant-select-selection-search input {
  padding-left: 10px !important;
  text-align: left;
}
.layout-dashboard .header-control .language-wrap .ant-select.ant-select-single.ant-select-show-arrow {
  width: 100%;
}
.userImg {
  height: 40px;
  width: 40px;
}
.profileDropdownMain .ant-image {
  height: 40px !important;
  width: 40px !important;
  margin: 0 !important;
}


  .sign-up-bg section.ant-layout.signup-page {
      padding: 15px;
      max-width: 100%;
  }

  .ant-col.padding-cls {
      padding-right: 0 !important;
  }
  .layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler svg path {
      fill: #000 !important;
  }
  .layout-dashboard .ant-layout-header {
      margin: 0;
      border-bottom: solid 1px #c7c7c7;
      border-radius: 0;
      padding: 16px;
      margin-bottom: 24px;
  }
  .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-space.ant-space-horizontal.ant-space-align-baseline {
      flex: 0 0 33.33%;
      max-width: 33.33%;
  }
  .edit-page-wrap .ant-row.ant-form-item-row {
      margin: 0;
  }
  .edit-page-wrap .ant-row {
      margin: 0;
  }
  .ant-form-item.add-types-cls {
      flex: 0 0 33.33%;
      max-width: 33.33%;
  }
  .ant-form-item.add-types-cls {
      flex: 0 0 33.33%;
      max-width: 33.33%;
  }  
  .layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler {
      padding-right: 0 !important;
  }
  .layout-dashboard .ant-row {
      padding: 0 !important;
      margin: 0 !important;
  }
  footer.ant-layout-footer {
    width: 100%;
  }
  .selectQuote {
    flex-direction: column;
  }
  .SectionMain > div {
    font-size: 20px;
  }
  .modal_title_cls {
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 30px;
  }
  .modal_sub_title_cls {
    font-size: 18px;
  }
  .veg-type-menu .ant-row.ant-form-item-row{
    flex-wrap: wrap;
  }
  .tab_inner_tit.menu-tab-mannage {
    padding: 15px 15px 15px 15px;
  }
  .sm-padding-0 {
    padding: 0 !important;
  }
}
@media (max-width: 767.98px) {
  span.ac-create a {
    color: #414454; 
    font-size: 14px; 
}
  .signin-bg {
    height: unset;
    padding: 35px 100px;
}
  .signin-box {
    padding: 35px 35px;
}
  button.ant-btn.ant-btn-default.edit-cls.btnStyle.primary_btn {
    height: 35px !important;
    padding: 5px 10px !important;
}
  .ant-modal.tab_modal .ant-modal-footer .ant-btn {
    min-height: 40px;
    padding: 8.5px 20px !important;
  }
  
  .modal-border-box {
      padding: 12px;
      margin-top: 10px !important;
  }
  .sec-first-status {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-upload.ant-upload-select button.ant-btn.ant-btn-default {
      padding: 5.5px 20px;
      min-height: 35px;
  }
.type-of-last {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    padding-top: 15px;
}

.space-items-box {
    flex-wrap: wrap;
}

.sec-first-status h5 {
    margin-bottom: 0;
    text-align: left;
}

  .sec-first-status .ant-form-item-control-input-content {
      border: 0 !important;
  }

  .status_item_cls .ant-form-item.cc-check-box.m-0.mandatory-status {
      max-width: 100px;
  }

  .status_item_cls .ant-form-item.cc-check-box.m-0.mandatory-status .ant-col.ant-form-item-label {
      margin-left: 5px;
  }

  .status_item_cls .ant-form-item.cc-check-box.m-0.mandatory-status label {
      font-size: 11px !important;
  }

  .ant-form-item.m-0.cc-check-box .ant-checkbox-inner {
      height: 15px;
      width: 15px;
  }

  .ant-modal.tab_modal .ant-form label span {
      padding-right: 4px !important;
      padding: 0;
      font-size: 11px;
  }

  .sort-input-type input {
      max-width: 100%;
      padding: 0 10px !important;
      margin: 0;
      min-height: 35px !important;
  }

  .ant-form-item.cc-check-box.recr-status+  .ant-list {
      max-width: 115px;
  }
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
      padding: 10px 12px !important;
  }
  .ant-card-head {
      padding: 12px 12px 12px !important;
  }
  .ratings-card-box {
      padding: 12px;
  }
  .ratings-card-text {
      margin-left: 10px;
  }
  .ftp_text {
      font-size: 13px;
  }
  .card-date-time span.ftp_text {
      line-height: 20px;
  }
  .ratings-card-text li.ant-rate-star.ant-rate-star-full {
      display: inline-flex;
  }
  .ratings-card-text li.ant-rate-star.ant-rate-star-full div {
      display: flex;
  }
  .ratings-card-text .rating-right  ul.ant-rate {
      line-height: 1;
      margin-bottom: 0;
      margin-top: 5px;
  }
    .add-btn {
      padding: 0;
  }
  .ratings-card-text h4{
    font-size: 15px;
  }
  .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-space.ant-space-horizontal.ant-space-align-baseline {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .ant-btn.ant-btn-dashed.ant-btn-block {
      padding: 10px 10px;
      width: 100%;
  }
  .ant-form-item.add-types-cls {
      margin: 0 0 15px 0 !important;
  }
  .ant-space-item {
      margin: 0 !important;
  }
  .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-row {
      margin: 0;
  }
  .ant-space.ant-space-horizontal.ant-space-align-baseline {
      padding: 0;
  }
  .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-space.ant-space-horizontal.ant-space-align-baseline {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .anticon[tabindex] {
    right: 0;
  }  
  .event_card_info {
        flex-direction: column;
    }

    .event_card_info .event-cont-dtl {
        margin-left: 0;
    }

    .event_card_info .event-cont-dtl .view-inner-cls.desc-wrap {
        flex-wrap: wrap;
    }
    .event-img-wrap {
       height: unset;
        flex: 0 0 100%;
    }
    .layout-dashboard.layout-dashboard-rtl .header-control {
      justify-content: flex-end;
    }

    .ant-card-head-wrapper .ant-card-extra {
      padding-top: 0 !important;
      flex-wrap: wrap;
      justify-content: start;
      /* align-items: start; */
    }
    .ant-card-head-wrapper {
      flex-direction: column !important;
  }
    .ant-card-head-wrapper .ant-card-head-title {
        padding-bottom: 0 !important;
        width: 100%;
    }
/* 01/06/2023 */
    .layout-dashboard .header-control {
      margin-top: 0;
    }
    .layout-dashboard .header-control {
        margin-top: 0;
    }
    .ant-row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    /* .ant-col {
        padding: 0 !important;
    } */

    .view-main-list {
      padding-right: 0;
      border-right: 0;
      margin-bottom: 15px;
    }
    .layout-dashboard .header-control svg {
        margin-right: 0 !important;
    }
    .ant-btn.ant-btn-link.sidebar-toggler {
        margin-right: 0 !important;
        padding-right: 0 !important;
    }
   
    .ant-modal {
        top: 0 !important;
    }
    .card-profile-head .ant-card-head {
        padding: 0 10px !important;
    }
    .card-profile-head .ant-card-head-wrapper .ant-avatar-group .avatar-info h4 {
        font-size: 14px;
    }
    .card-profile-head .ant-card-head-wrapper .ant-avatar-group .avatar-info p {
        font-size: 12px;
    }
    .brandManager .ant-card-head-wrapper .ant-card-extra,
    .productAttribute .ant-card-head-wrapper .ant-card-extra,
    .categoryService .ant-card-head-wrapper .ant-card-extra,
    .quoteManagement .ant-card-head-wrapper .ant-card-extra,
    .blog .ant-card-head-wrapper .ant-card-extra {
      flex-wrap: nowrap;
    }
    .backArrowBtn {
      display: block;
  }
  .chatMainOuter {
    position: relative;
  }
  :root {
    --contactWidth: 100%;
}
  .chatMainOuter .chatingOuter {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    transition: ease-in-out .3s;
    opacity: 1;
  }
  .chatMainOuter .chatingOuter:not(.active) {
    right: -100%;
    opacity: 0;
  }
  
  .chatMainOuter .contactOuter .contactBody, .chatMainOuter .chatingOuter .chatingBody {
      height: 0;
  }
  .SectionMain > div {
    font-size: 18px;
}
.rating-right { 
  flex-wrap: wrap;
}
.pageHeadingSearch.desh-input-search input {
  min-width: 200px !important;
}
.layout-dashboard .ant-layout-content {
  padding: 0;
  margin: 0 0px 0;
}
.pageHeadingSearch .searchInput .ant-input { 
  min-width: 150px !important;
}

.mendatory-colam-box {
  flex-wrap: wrap;
}
.custom-items-inner .ant-col {
  padding: 0 !important;
}
.ant-form-item-control-input-content input {
    min-height: 35px;
    padding: 5px 15px;
  }
  .ant-select.ant-select-in-form-item {
    height: 100%!important;
  }
  button.add-item-btn {
    height: 35px !important;
  }
  .addDelete_option .minus-wrap {
    flex: 0 0 35px;
    max-width: 35px;
    width: 35px;
    height: 35px;
}

.ant-space.ant-space-horizontal.ant-space-align-baseline.gap-cls .ant-space-item .ant-col.ant-col-12.ant-col-md-6 {
    padding: 0 !important;
}
.ant-space.ant-space-horizontal.ant-space-align-baseline.gap-cls .ant-space-item .ant-col.ant-col-12.ant-col-md-6 .ant-col.ant-form-item-control {
    padding: 0;
}

.ant-space.ant-space-horizontal.ant-space-align-baseline.gap-cls .ant-space-item .ant-col.ant-col-12.ant-col-md-6 .ant-col.ant-form-item-label {
    padding-left: 0;
}
.add-menu-items-delete button.ant-btn.ant-btn-default.ant-btn-block.primary_btn.btnStyle {
  width: 40px !important;
  height: 40px !important;
}
.add-menu-items-delete button.ant-btn.ant-btn-default.ant-btn-block.primary_btn.btnStyle i + span {
  display: none;
}
.add-menu-items-delete .ant-form-item-control-input-content {
  width: auto !important;
}
.add-menu-items-delete .ant-form-item-control-input {
  width: auto !important;
}
.bank-text-box{
  display: grid;
  align-items: baseline;
}
.bank-text-box span {
  font-family: Visby Round CF;
  font-size: 13px; 
}

}
@media (max-width:575.98px){
  .bank-text-box span {
    font-family: Visby Round CF;
    font-size: 12px; 
  }
  .social-media-sec { 
    flex: 0 0 100%;
}
  span.anticon.anticon-delete.delete-circal {
    width: 35px;
    height: 35px;
  }
  .add-menu-items-delete button.ant-btn.ant-btn-default.ant-btn-block.primary_btn.btnStyle {
      width: 35px !important;
      height: 35px !important;
  }
  .add-menu-items-delete button.ant-btn.ant-btn-default.ant-btn-block.primary_btn.btnStyle i.me-1 {
      margin: 0 !important;
  }
  .status_item_cls {
      flex-wrap: wrap;
  }
  .box-mannage-outer {
    margin-bottom: 10px;
  }

  .btnStyle {
    padding: 9px 13px !important;
  }
  .ant-btn-primary {
    height: 35px !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 35px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 32px !important;
  }
  .ant-input-wrapper .ant-input-group-addon .ant-btn-default {
      height: 35px !important;
  }
  input {
      height: 35px !important;
  }
  .pageHeadingSearch .searchInput .ant-input {
      min-width: 100% !important;
  }
  .pageHeadingSearch {
      margin-right: 5px;
  }
  .ant-steps-vertical {
    display: flex !important;
    flex-direction: unset !important;
    max-width: 100%;
    justify-content: center;
    margin: 0 auto !important;
}
.ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
  float: none !important;
  margin-right: 0 !important;
}
.ant-steps-vertical>.ant-steps-item {
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: center;
  margin: 0 auto;
}
.ant-steps-item-content, .ant-steps-item-icon {
  display: inline-block !important;
  vertical-align: top;
  overflow: visible !important;
}
.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail:after {
  width: 45px !important;
  height: 6px !important;
}
.ant-steps-item-container {
  width: 100% !important; 
}

.ant-steps-item {width: calc(100% / 3 );position: relative;}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
  width: 100% !important;
  left: calc(50% - 7px) !important;
  top: calc(50% - 10px) !important;
  padding: 0 !important;
  bottom: 0 !important;
  height: 5px;
}

.ant-steps.ant-steps-vertical {
  justify-content: center;
  text-align: center;
}
.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {
  background-color: #f2df33 !important;
}
.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 1px;
  height: 4px;
}

.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0,0,0,.85);
  font-size: 16px;
  line-height: 32px;
}
  .ant-card-head-wrapper .ant-card-extra button.ant-btn + span {
    width: 100%;
    display: block;
  }
  .ant-card-head-wrapper .ant-card-extra button.ant-btn + span .ant-upload.ant-upload-select.ant-upload-select-picture {
    width: 100%;
  }
  .ant-form-item.add-types-cls {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .ant-card-head-wrapper .ant-card-extra .ant-btn {
      width: 100%;
  }

  span.ant-input-group-wrapper.ant-input-search.ant-input-search-large.ant-input-group-wrapper-lg span.ant-input-wrapper.ant-input-group {
      max-width: 100%;
  }

  span.ant-input-group-wrapper.ant-input-search.ant-input-search-large.ant-input-group-wrapper-lg span.ant-input-wrapper.ant-input-group button.ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
      min-width: 50px;
  }
  .view-inner-cls h6 {
      font-size: 11px;
  }
  .view-inner-cls h5 {
      font-size: 12px;
  }
  .signin-box h5 {
      font-size: 14px !important;
      margin-bottom: 10px !important;
  }
  .signin-box h1 {
      font-size: 16px !important;
  }
  .ant-btn {
      padding: 5px 10px;
      font-size: 12px !important;
  }
  th, td {
      padding: 5px 3px !important;
      font-size: 12px;
  }
  .assignment-chart-list .ant-card.card-project p {
      font-size: 12px;
      padding: 0 10px;
  }
  .ant-breadcrumb-separator {
      margin: 0 3px;
  }
  span.ant-breadcrumb-link {
      font-size: 12px;
  }
  .ant-input {
    font-size: 12px;
  }
  .breadcrumbMain {
    display: none !important;
  }
  /* .header-control {
    justify-content: space-between !important;
  } */
  .header-control .profileDropdownMain .ant-btn {
    order: 2 !important;
    text-align: left !important;
  }
  .ant-picker.ant-picker-range, .ant-picker.ant-picker-range.ant-picker-focused {
    width: 100%;
}
.searchOuter {
  flex-direction: column;
}
.ant-picker .ant-picker-input > input {
  width: 100%;
}
.mt-xs-2 {
  margin-top: 8px;
}
.brandManager .ant-card-head-wrapper .ant-card-extra,
.categoryService .ant-card-head-wrapper .ant-card-extra {
  flex-wrap: wrap;
}

.view-inner-cls.other-image.productDetail .ant-image {
  width: 60px !important;
  height: 60px !important;
}
.logoOuter {
  height: 140px;
  width: 100px;
}
.ant-card-head-title h4 {
  font-size: 16px !important;
}
.ant-card-head-wrapper {
  gap: 10px;
  align-items: center;
}

.modal_title_cls {
  font-size: 18px;
  margin-bottom: 20px;
}
.modal_sub_title_cls {
  font-size: 15px;
}
.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  max-width: 300px;
  left: 10px;
}
.notification-inner {
  padding: 0 15px 15px;
}
.notification-header-menu {
  padding: 12px 15px;
}
.viewAll_notification {
  padding-bottom: 15px;
}
.main-first-notification {
  padding: 12px 0;
}
.notification-icons {
  margin-right: 10px;
}
.revenue-card-box {
  padding: 0px 0px;
  border-bottom: 0px solid #00000017;
}
.space-items-box{
  flex-wrap: wrap;
}
.pageHeadingSearch .searchInput .ant-input { 
  padding: 10px 15px !important;
  font-size: 12px !important;
  height: 35px !important; 
}
.ant-table-tbody>tr>td {
  font-size: 12px; 
  line-height: 18px; 
}
.ant-table-thead>tr>th { 
  font-size: 12px !important; 
}
.ant-card.ant-card-bordered.collection_card .ant-card-body{
  flex-wrap: wrap ;
}
}
@media (max-width: 480px){
  .tabLogo {
    padding-left: 30px;
}
.tabLogo img {
  width: 80% !important;
}
.layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler {
  position: absolute;
  left: 0;
  top: -40px;
}
  .online-swich {
    position: absolute;
    right: 78px;  
}
  .restaurantReview {
    flex-wrap: wrap;
}
  .wlt-setting {
    margin: 10px 10px; 
}
.wlt-section{
  padding: 15px;
}
.wlt-text p { 
  font-size: 14px;  
  word-break: break-all;
  line-height: 24px;
  text-wrap: wrap;
  display: inline-block;
}
.wlt-text h5 {
  font-size: 14px;
  word-break: break-all;
  word-wrap: break-word;
  display: inline-block;
  text-wrap: balance;
}
.wlt-text span{
  font-size: 14px;
}
.wlt-text h6 {
  font-size: 16px; 
  line-height: 18px; 
  margin-bottom: 0px;
}
.wlt-amount h4{
  font-size: 14px;
}
.wlt-card-bottam .wlt-text h6{
  font-size: 14px;
}
.wlt-bank-details {
  flex-wrap: wrap;
}
.bank-text-box p {
  font-size: 12px;
  margin-right: 7px;
  line-height: 18px;
}
.bank-text-box span { 
  font-size: 12px; 
  line-height: 18px;
}

  label {
    font-size: 11px !important;
}
  /* .notificationDropdownMain {
      display: none;
  } */
  .productAttribute .ant-card-head-wrapper .ant-card-extra,
  .quoteManagement .ant-card-head-wrapper .ant-card-extra,
  .blog .ant-card-head-wrapper .ant-card-extra {
    flex-wrap: wrap;
  }
  .w-xxs-100 {
    width: 100%;
  }
  .sales-text-outer h6 { 
    font-size: 12px; ;
  }
  .sales-text-outer h4 { 
    font-size: 14px; 
}
.sales-img-icon {
  padding: 5px;
  margin: 5px 5px;
  width: 40px;
  height: 40px; 
  flex: 0 0 40px;
}
 
.ant-card.ant-card-bordered.collection_card .ant-card-body {
  flex-wrap: wrap;
}
.invoice-btn-sec{
  flex-wrap: wrap;
}
.collection_dtl_body {
  padding: 0px;
}
.add-saler-amt span i {
  width: 25px;
  height: 25px; 
}
.ant-modal.tab_modal .ant-modal-footer .ant-btn { 
  border-radius: 60px !important;
  padding: 8px 16px !important;
  min-width: 100px; 
  font-size: 13px; 
}
 
button.manag-btn {
  padding: 10px 10px !important;
}
.dashboard-cards .ant-card-body {
  padding: 20px;
}
.dash-box-top h4 {
  font-size: 20px; 
  line-height: normal;
}
.dash-box-top p { 
  font-size: 12px; 
  line-height: 24px; 
  margin-bottom: 0px;
}

.ant-modal.tab_modal .ant-modal-content { 
  padding: 15px;
}
.tab_inner_tit h3 { 
  font-size: 16px;
  margin-bottom: 10px; 
}
 
.ant-tabs.ant-tabs-top.main_tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 12px; 
  padding: 10px 12px; 
}
.notification-card h4 { 
  font-size: 14px; 
  margin-bottom: 0px; 
}
.notification-card .ant-list-item-meta-description {
  font-size: 12px; 
  line-height: 16px; 
}
.notification-card .ant-list-item-meta .ant-avatar-image {
  height: 40px;
  width: 40px;
}
.ant-typography h5, div.ant-typography-h5, div.ant-typography-h5>textarea, h5.ant-typography {
  font-size: 14px !important; 
}

.text-head_right_cont{
  gap: 0;
}
 
}
@media (max-width: 360px){
  .wlt-text h6 {
    font-size: 11px;
    line-height: 18px;
    margin-bottom: 0px;
}
  .tabLogo img {
    width: 57% !important;
}
  .online-swich { 
    font-size: 12px;
}
  .bank-text-box span {
    font-size: 10px;
    line-height: 18px;
}
  .checkBox_wrap .ant-checkbox-wrapper span.ant-checkbox-inner { 
    min-width: 30px;
    min-height: 30px; 
}
.checkBox_wrap .ant-checkbox-wrapper span { 
  height: 30px;
  width: 30px; 
  font-size: 12px; 
}
  .button_group {
    flex-direction: column;
    width: 100%;
  }
  .btnStyle {
    font-size: 11px !important; 
    padding: 9px 8px !important;
}

}

  .print-container {
    page-break-before: always;
  }